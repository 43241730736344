<template>
  <!-- eslint-disable vue/v-on-handler-style -->

  <v-dialog v-model="rolloutDialog" width="1000" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ action.title }} Firmware Rollout</span>
      </v-card-title>

      <v-card-text>
        <div class="text-overline px-3">Firmware file</div>

        <v-menu offset-y :disabled="loading">
          <template #activator="{ props }">
            <div v-bind="props" class="px-3">
              <v-data-table
                hide-default-header
                hide-default-footer
                no-data-text="No file selected"
                style="border-radius: 0; border-bottom: 1px solid grey"
                :loading="loading"
                :headers="headers"
                :items="file ? [file] : []"
              />
            </div>
          </template>

          <v-card style="max-height: 300px; overflow: auto">
            <v-data-table
              hide-default-footer
              :loading="loading"
              :headers="headers"
              :items="rollout ? filteredFiles : otaFiles"
              :items-per-page="100"
              @click:row="(_event: any, row: any) => (file = row.item)"
            />
          </v-card>
        </v-menu>

        <div class="text-overline mt-8 px-3">Target preview</div>

        <v-row class="px-3">
          <v-col class="d-flex">
            <v-text-field label="Harware type" readonly hide-details :model-value="hardwareType" />
          </v-col>
          <v-col class="d-flex">
            <v-text-field label="App flavor" readonly hide-details :model-value="team.appFlavor" />
          </v-col>
        </v-row>

        <v-row class="px-3">
          <v-col>
            <v-text-field label="Label" readonly hide-details :model-value="'research:id:' + team.id" />
          </v-col>
          <v-col>
            <v-text-field label="Priority" readonly hide-details :model-value="60" />
          </v-col>
        </v-row>

        <div class="text-overline px-3 pt-8">Schedule preview</div>

        <v-row class="px-3">
          <v-col>
            <v-text-field v-model="rolloutDate" readonly hide-details label="Rollout date" />
          </v-col>

          <v-col>
            <v-text-field v-model="schedules[0].title" readonly hide-details label="Schedule type" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn class="mr-2" text="Cancel" @click="close()" />
        <v-btn
          color="primary"
          :text="action.title == 'Edit' ? 'Save' : 'Publish'"
          :disabled="!file || loading"
          @click="publishRollout()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Model, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { compare } from 'compare-versions'

  import { ALL_RING_TYPES } from '@jouzen/outo-toolkit-vuetify'

  import { createHeaders, createSchedules } from '#views/ota/constants'

  import { AppStore, OtaStore } from '#stores'

  import { OtaFile, Rollout } from '#types'

  @Component
  class SetupRollout extends Vue {
    @Prop() public team!: any

    @Prop() public action!: any
    @Prop() public rollout!: Rollout | null
    @Prop() public hwType!: string

    @Model() public rolloutDialog!: boolean

    @Emit('close')
    public close() {
      this.file = null

      this.rolloutDialog = false
    }

    @Emit('confirm')
    public confirm(rollout: Rollout) {
      return rollout
    }

    public rolloutDate: string = new Date().toISOString().split('T')[0]
    public rolloutMenu = false

    public file: any = null

    public readonly headers = createHeaders
    public readonly schedules = createSchedules

    private readonly appStore = new AppStore()
    private readonly otaStore = new OtaStore()

    public get loading() {
      return this.otaStore.loading
    }

    public get userEmail() {
      return this.appStore.userEmail
    }

    public get rollouts() {
      return this.otaStore.rollouts?.[this.hwType] ?? []
    }

    public get otaFiles() {
      return this.otaStore.files?.[this.hwType] ?? []
    }

    public get hardwareType() {
      return ALL_RING_TYPES.find((h: { title: string; value: string }) => h.value === this.hwType)?.title ?? ''
    }

    public get filteredFiles() {
      if (this.rollout) {
        // if rollout state is either disabled or active, filter out files that have older versions
        if (this.rollout.state === 'active' || this.rollout.state === 'disabled') {
          return this.otaFiles.filter((f: OtaFile) => compare(f.version, this.rollout!.value.version, '>'))
        }
      }

      return this.otaFiles
    }

    public async mounted() {
      await this.listFiles()
    }

    public async listFiles() {
      await this.otaStore.listFiles()
    }

    public async publishRollout() {
      // if action is edit, we archive the rollout and create a new one
      if (this.action.title === 'Edit') {
        await this.otaStore.changeState({
          state: this.action.value,
          rollout: this.rollout,
        })
      }

      let value: any = null

      if (this.file) {
        value = {
          slug: this.file.slug,
          type: this.file.type,
          version: this.file.version,
        }
      }

      const rolloutToAdd = {
        type: 'otafile',
        value: value,
        schedule: {
          style: 'daily',
          step_count: 1,
          date_start: this.$dayjs(this.rolloutDate).utc(true).add(6, 'h').toISOString().split('T')[0],
        },
        filters: [`label:research:id:${this.team.id}`],
        info: {
          source: 'poirot',
          actor: this.userEmail,
        },
        flavor: this.team.appFlavor,
        priority: 60,
      }

      const response = await this.otaStore.createRollout({ rollout: rolloutToAdd })

      if (response?.data) {
        this.confirm(response.data as Rollout)
      }

      this.close()
    }
  }

  export default toNative(SetupRollout)
</script>
