<template>
  <!-- eslint-disable vue/v-on-handler-style -->

  <v-dialog v-if="open" persistent width="800" :model-value="true">
    <v-card :loading="publishing">
      <v-card-title>Create a new OTA rollout</v-card-title>

      <v-card-text>
        <div class="text-overline px-3">Target</div>

        <v-row class="px-3">
          <v-col class="d-flex" cols="6">
            <v-select v-model="hwType" label="Hardware type" :items="hwTypes" />
          </v-col>
          <v-col class="d-flex" cols="6">
            <v-select
              label="App flavor"
              :items="appFlavors"
              :model-value="appFlavor"
              @update:model-value="appFlavor = $event"
            />
          </v-col>
        </v-row>

        <v-radio-group v-model="target" @update:model-value="resetRolloutFilters('target', $event)">
          <v-row class="px-3">
            <v-col class="d-flex" cols="6">
              <v-radio value="all" label="Rollout to all users" />
            </v-col>

            <v-col class="d-flex" cols="6">
              <v-radio value="group">
                <template #label>
                  <v-text-field
                    v-model="group"
                    persistent-placeholder
                    label="Only rollout to specific group"
                    placeholder="fw-beta"
                    @update:model-value="resetRolloutFilters('group', $event)"
                    @click.stop=""
                  />
                </template>
              </v-radio>
            </v-col>
          </v-row>

          <v-row class="px-3 mt-n2">
            <v-col class="d-flex" cols="6">
              <v-radio value="oura">
                <template #label>
                  <v-select
                    v-model="domain"
                    persistent-placeholder
                    label="Only rollout to internal Oura users"
                    placeholder="Select internal domain..."
                    :items="internalDomains"
                    @update:model-value="resetRolloutFilters('oura', $event)"
                    @click.stop=""
                  />
                </template>
              </v-radio>
            </v-col>

            <v-col class="d-flex" cols="6">
              <div class="d-flex flex-row" style="width: 100%">
                <v-radio value="label">
                  <template #label>
                    <v-select
                      v-model="label"
                      persistent-placeholder
                      label="Only rollout to specific users"
                      placeholder="Select sample list..."
                      no-data-text="No suitable sample filters exist"
                      :items="sampleLabels"
                      @update:model-value="resetRolloutFilters('label', $event)"
                      @click.stop=""
                    />
                  </template>
                </v-radio>
              </div>
            </v-col>
          </v-row>
        </v-radio-group>

        <div class="text-overline px-3 pt-3">File*</div>

        <v-menu offset-y :disabled="isLoading">
          <template #activator="{ props }">
            <div v-bind="props" class="px-3">
              <v-data-table
                hide-default-header
                hide-default-footer
                no-data-text="No file selected"
                style="border-radius: 0; border-bottom: 1px solid grey"
                :loading="isLoading"
                :headers="headers"
                :items="file ? [file] : []"
                :items-per-page="1"
              />
            </div>
          </template>

          <v-card style="max-height: 300px; overflow: auto">
            <v-data-table
              hide-default-footer
              :loading="isLoading"
              :headers="headers"
              :items="firmwareOtaFiles"
              :items-per-page="100"
              @click:row="(_event: any, row: any) => (file = row.item)"
            />
          </v-card>
        </v-menu>

        <div class="text-overline px-3 pt-8">Schedule</div>

        <v-row class="px-3">
          <v-col class="d-flex" cols="6">
            <v-menu v-model="rolloutMenu" offset-y min-width="auto" :close-on-content-click="false">
              <template #activator="{ props }">
                <v-text-field
                  v-bind="props"
                  v-model="rolloutDate"
                  readonly
                  label="Rollout date*"
                  :rules="[requiredField]"
                />
              </template>

              <v-date-picker
                :model-value="$dayjs(rolloutDate || undefined).toDate()"
                @update:model-value="(rolloutDate = $dayjs($event).format('YYYY-MM-DD')), (rolloutMenu = false)"
              />
            </v-menu>
          </v-col>

          <v-col class="d-flex flex-column" cols="6">
            <v-select
              v-model="schedule"
              label="Schedule type*"
              :items="schedules"
              :disabled="target === 'group' || target === 'label'"
            />

            <v-text-field v-if="schedule === 'manual'" v-model="percentage" type="number" label="Rollout percentage" />
          </v-col>
        </v-row>

        <div class="text-overline px-3 pt-3">Platform</div>

        <v-row class="px-3">
          <v-col cols="4" class="d-flex">
            <v-select v-model="appPlatform" label="Platform" :items="appPlatforms" />
          </v-col>

          <v-col cols="4" class="d-flex">
            <v-text-field
              v-model="appMinVersion"
              label="Min version"
              :disabled="!appPlatform"
              :rules="[validateVersion]"
            />
          </v-col>

          <v-col cols="4" class="d-flex">
            <v-text-field
              v-model="appMaxVersion"
              label="Max version"
              :disabled="!appPlatform"
              :rules="[validateVersion]"
            />
          </v-col>
        </v-row>

        <div class="text-overline px-3 pt-3">Ecore version</div>
        <v-row class="px-3">
          <v-col cols="6" class="d-flex">
            <v-text-field v-model="ecoreMinVersion" label="Min version" :rules="[validateVersion]" />
          </v-col>

          <v-col cols="6" class="d-flex">
            <v-text-field v-model="ecoreMaxVersion" label="Max version" :rules="[validateVersion]" />
          </v-col>
        </v-row>

        <div class="text-overline px-3 pt-3">Firmware version</div>
        <v-row class="px-3">
          <v-col cols="6">
            <v-text-field v-model="fwMinVersion" label="Min version" :rules="[validateVersion]" />
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="fwMaxVersion" label="Max version" :rules="[validateVersion]" />
          </v-col>
        </v-row>

        <div class="text-overline px-3 pt-3">Bootloader version</div>
        <v-row class="px-3">
          <v-col cols="6">
            <v-text-field v-model="bootloaderMinVersion" label="Min version" :rules="[validateVersion]" />
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="bootloaderMaxVersion" label="Max version" :rules="[validateVersion]" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-checkbox
          v-model="duplicate"
          label="Create additional 100% rollout to internal users"
          :disabled="publishing || (target !== 'all' && target !== 'oura')"
        />

        <v-spacer />

        <v-btn class="mr-2" text="Cancel" @click="close()" />
        <v-btn text="Publish" color="primary" :disabled="hasValidConfiguration" @click="publishRollout()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Model, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { ALL_RING_TYPES } from '@jouzen/outo-toolkit-vuetify'

  import { appFlavors, appPlatforms, createHeaders, createSchedules, internalDomains } from '#views/ota/constants'

  import { AppStore, OtaStore, SetupStore } from '#stores'

  @Component
  class CreateRollout extends Vue {
    @Prop() public hw!: string
    @Prop() public open!: boolean

    @Model() public appFlavor!: string

    @Emit('close')
    public close(selected?: any) {
      return selected ?? null
    }

    public domain = ''
    public target = 'oura'

    public percentage = '0'

    public hwType = ''
    public rolloutDate = ''
    public appPlatform = ''
    public appMinVersion = ''
    public appMaxVersion = ''

    public duplicate = false
    public publishing = false

    public rolloutMenu = false

    public file: any = null
    public schedule: any = null

    public group: string | null = null

    public fwMinVersion: string | null = null
    public fwMaxVersion: string | null = null

    public ecoreMinVersion: string | null = null
    public ecoreMaxVersion: string | null = null

    public bootloaderMinVersion: string | null = null
    public bootloaderMaxVersion: string | null = null

    public label: { uid: string; name: string } | null = null

    public readonly hwTypes = ALL_RING_TYPES

    public readonly headers = createHeaders
    public readonly schedules = createSchedules

    public readonly appFlavors = appFlavors
    public readonly appPlatforms = appPlatforms
    public readonly internalDomains = internalDomains

    private readonly appStore = new AppStore()
    private readonly otaStore = new OtaStore()
    private readonly setupStore = new SetupStore()

    private readonly versionPattern = /^\d{1,10}\.\d{1,10}\.\d{1,10}$/

    public get user() {
      return this.appStore.user
    }

    public get isLoading() {
      return this.otaStore.loading
    }

    public get sampleLabels() {
      return this.sampleFilters.map((s) => ({ title: s.name, value: { uid: s.uid ?? '', name: s.name } }))
    }

    public get sampleFilters() {
      return this.setupStore.sampleFilters.filter((s) => s.type === 'user_uuid')
    }

    public get hwTypeRollouts() {
      return this.otaStore.rollouts?.[this.hwType] ?? []
    }

    public get firmwareOtaFiles() {
      // We only allow rollout to production for files that has been rollouted to staging or experimental

      if (this.appFlavor === 'release') {
        return (this.otaStore.files?.[this.hwType] ?? []).filter(
          (f: any) =>
            f.state === 'verified' &&
            f.type === `firmware_${this.hwType}` &&
            this.hwTypeRollouts.find(
              (r: any) => r.value.version === f.version && (r.flavor === 'staging' || r.flavor === 'experimental'),
            ),
        )
      } else {
        return (this.otaStore.files?.[this.hwType] ?? []).filter(
          (f: any) => f.type === `firmware_${this.hwType}` && f.state === 'verified' && f.uploaded_at,
        )
      }
    }

    public get hasValidConfiguration() {
      const isInvalidVersion = (version: string | null) => {
        return !!version && !this.versionPattern.test(version)
      }

      return (
        this.publishing ||
        !this.file ||
        !this.schedule ||
        !this.hwType ||
        !this.rolloutDate ||
        (this.target === 'group' && !this.group) ||
        (this.target === 'label' && !this.label) ||
        isInvalidVersion(this.fwMinVersion) ||
        isInvalidVersion(this.fwMaxVersion) ||
        isInvalidVersion(this.appMinVersion) ||
        isInvalidVersion(this.appMaxVersion) ||
        isInvalidVersion(this.ecoreMinVersion) ||
        isInvalidVersion(this.ecoreMaxVersion) ||
        isInvalidVersion(this.bootloaderMinVersion) ||
        isInvalidVersion(this.bootloaderMaxVersion)
      )
    }

    @Watch('hw', { immediate: true })
    protected hWChanged() {
      this.hwType = this.hw
    }

    @Watch('hwType', { immediate: true })
    protected hWTypeChanged() {
      this.file = null
    }

    @Watch('appFlavor', { immediate: true })
    protected appFlavorChanged() {
      this.file = null

      this.setupStore.listSampleFilters(this.appFlavor)
    }

    public async publishRollout() {
      let value: any = null
      let schedule: any = null

      this.publishing = true

      if (this.file) {
        value = {
          slug: this.file.slug,
          type: this.file.type,
          version: this.file.version,
        }
      }

      if (this.schedule === 'manual') {
        schedule = {
          style: 'daily',
          step_list: [parseInt(this.percentage)],
          date_start: this.$dayjs(this.rolloutDate).utc(true).add(6, 'h').toISOString().split('T')[0],
        }
      } else {
        const scheduleStyle = (this.schedule ?? '').split('-')[0]
        const scheduleSteps = (this.schedule ?? '').split('-')[1].split(':')

        if (scheduleSteps.length === 1) {
          schedule = {
            style: scheduleStyle,
            step_count: 1,
            date_start: this.$dayjs(this.rolloutDate).utc(true).add(6, 'h').toISOString().split('T')[0],
          }
        } else if (scheduleSteps.length > 1) {
          schedule = {
            style: scheduleStyle,
            step_list: scheduleSteps.map((s: string) => parseInt(s)),
            date_start: this.$dayjs(this.rolloutDate).utc(true).add(6, 'h').toISOString().split('T')[0],
          }
        }
      }

      const rollout: any = {
        type: 'otafile',
        value: value,
        schedule: schedule,
        filters: [],
        info: {
          source: 'poirot',
          actor: this.user.email,
        },
      }

      if (this.label) {
        rollout.info.list = this.label.uid
      }

      if (this.appFlavor) {
        rollout.flavor = this.appFlavor
      }

      if (this.appPlatform) {
        rollout.platform = this.appPlatform

        if (this.appMinVersion) {
          rollout.min_version = this.appMinVersion
        }

        if (this.appMaxVersion) {
          rollout.max_version = this.appMaxVersion
        }
      }

      if (this.target === 'oura') {
        if (!this.domain) {
          rollout.filters.push('oura_internal')
        } else {
          rollout.filters.push(`label:oura_internal:domain:${this.domain}`)
        }
      }

      if (this.target === 'group' && this.group) {
        rollout.filters.push(`oura_email:${this.group}`)
      }

      if (this.target === 'label' && this.label) {
        const details = await this.setupStore.fetchSampleFilter(this.appFlavor, this.label.uid)

        const label = `rollout:firmware:${this.hwType}:${this.appFlavor ?? 'release'}:${this.file.version.replace(
          /\./g,
          '_',
        )}`

        await this.otaStore.createRolloutLabel(this.appFlavor, { label, users: details?.data ?? [] })

        rollout.filters.push(`label:${label}`)
      }

      if (this.ecoreMinVersion || this.ecoreMaxVersion) {
        const minVersion = this.ecoreMinVersion ? this.ecoreMinVersion : '0.0.0'
        const maxVersion = this.ecoreMaxVersion ? `:${this.ecoreMaxVersion}` : ''

        rollout.filters.push(`v:ecore:${minVersion}${maxVersion}`)
      }

      if (this.fwMinVersion || this.fwMaxVersion) {
        const minVersion = this.fwMinVersion ? this.fwMinVersion : '0.0.0'
        const maxVersion = this.fwMaxVersion ? `:${this.fwMaxVersion}` : ''

        rollout.filters.push(`v:firmware_${this.hwType}:${minVersion}${maxVersion}`)
      }

      if (this.bootloaderMinVersion || this.bootloaderMaxVersion) {
        const minVersion = this.bootloaderMinVersion ? this.bootloaderMinVersion : '0.0.0'
        const maxVersion = this.bootloaderMaxVersion ? `:${this.bootloaderMaxVersion}` : ''

        rollout.filters.push(`v:bootloader_${this.hwType}:${minVersion}${maxVersion}`)
      }

      await this.otaStore.createRollout({ rollout: rollout, label: this.label })

      if (this.duplicate) {
        this.duplicate = false

        this.domain = ''
        this.target = 'oura'
        this.schedule = 'daily-1'

        this.publishRollout()
      } else {
        this.close({ app: this.appFlavor, hw: this.hwType })

        this.publishing = false
      }
    }

    public requiredField(value: string) {
      return !!value || '*This field is required.'
    }

    public validateVersion(version: string) {
      if (!!version && !this.versionPattern.test(version)) {
        return 'Invalid version'
      }

      return true
    }

    public resetRolloutFilters(filter: string, value: any | null) {
      if (filter === 'oura' && !!value) {
        this.target = 'oura'
        this.schedule = 'daily-1'
      } else if (filter === 'group' && !!value) {
        this.target = 'group'
        this.schedule = 'daily-1'
      } else if (filter === 'label' && !!value) {
        this.target = 'label'
        this.schedule = 'daily-100:100:100:100:100:100:100:0'
      } else if (filter === 'target' && value === 'group') {
        this.schedule = 'daily-1'

        if (!this.group) {
          this.group = 'fw-beta'
        }
      } else if (filter === 'target' && value === 'label') {
        this.schedule = 'daily-100:100:100:100:100:100:100:0'

        if (!this.label) {
          this.label = this.sampleLabels[0]?.value ?? null
        }
      }
    }
  }

  export default toNative(CreateRollout)
</script>
