<template>
  <!-- eslint-disable vue/v-on-handler-style -->
  <v-app-bar>
    <v-tabs style="max-width: 500px" :model-value="tab">
      <v-tab to="rollouts">Rollouts</v-tab>
      <v-tab to="otafiles">OTA Files</v-tab>
    </v-tabs>

    <v-spacer />

    <v-btn icon="mdi-reload" :color="isLoading ? 'primary' : ''" :loading="isLoading" @click="refreshData()" />
  </v-app-bar>

  <v-container>
    <v-row>
      <v-col v-if="tab === 'rollouts'" cols="9">
        <div class="text-h5 font-weight-light">Firmware versions rolled out to the apps</div>

        <div class="text-subtitle-2 text-grey-darken-2 font-weight-light">
          <template v-if="isOtaRolloutsAdmin">
            You can create new rollouts and activate or disable existing ones
          </template>
          <template v-else>You can only view rollouts, apply for admin rights in IT portal</template>
        </div>
      </v-col>

      <v-col v-else-if="tab === 'otafiles'" cols="9">
        <div class="text-h5 font-weight-light">Firmware OTA files available for rollouts</div>

        <div class="text-subtitle-2 text-grey-darken-2 font-weight-light">
          <template v-if="isOtaRolloutsAdmin">
            You can create new rollouts and activate or disable existing ones
          </template>
          <template v-else>You can only view otafiles, apply for admin rights in IT portal</template>
        </div>
      </v-col>

      <v-col cols="3" class="d-flex justify-end align-top">
        <v-btn text="Create rollout" color="primary" :disabled="!isOtaRolloutsAdmin" @click="createDialog = true" />
      </v-col>
    </v-row>

    <v-row class="mt-4 align-center">
      <v-col>
        <v-select v-model="routeParams.hwType" label="Hardware type" :items="hwTypes" />
      </v-col>
      <v-col v-if="tab === 'rollouts'">
        <v-select v-model="routeParams.appFlavor" label="App flavor" :items="appFlavors" />
      </v-col>
      <v-col v-if="tab === 'rollouts'">
        <v-select v-model="routeParams.appPlatform" label="App platform" :items="appPlatforms" />
      </v-col>
      <v-col v-if="tab === 'rollouts'">
        <v-select v-model="routeParams.rolloutState" label="Rollout state" :items="rolloutStates" />
      </v-col>
      <v-col v-if="tab === 'otafiles'">
        <v-select v-model="routeParams.otafileState" label="OTA file state" :items="otafileStates" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-sheet>
          <v-data-table
            v-if="tab === 'rollouts'"
            no-data-text="No rollouts with selected filters"
            :loading="isLoading"
            :headers="rolloutHeaders"
            :items="firmwareRollouts"
            :items-per-page="20"
            :items-per-page-options="[
              { title: '20', value: 20 },
              { title: '50', value: 50 },
              { title: '100', value: 100 },
            ]"
            @click:row="(_event: any, row: any) => showPreviewDialog(null, row.item)"
          >
            <template #item.created_at="{ item }">
              {{ new Date(item.created_at).toUTCString() }}
            </template>

            <template #item.filters="{ item }">
              {{ item.filters.map((f: any) => f.split(':').splice(0, 3).join(':')).join(',') || 'No filters used' }}
            </template>

            <template #item.platform="{ item }">
              {{
                item.platform ? (appPlatforms.find((p: any) => p.value === item.platform) || {}).title || '' : 'Both'
              }}
              {{ item.min_version && !item.max_version ? '>=' : '' }}
              {{ item.min_version ? item.min_version : '' }}

              {{ item.min_version && item.max_version ? '-' : '' }}

              {{ !item.min_version && item.max_version ? '=<' : '' }}
              {{ item.max_version ? item.max_version : '' }}
            </template>

            <template #item.state="{ item }">
              <span class="state" :class="item.state">{{ item.state.toUpperCase() }}</span>
            </template>

            <template #item.actions="{ item }">
              <div @click.stop="">
                <v-menu left offset-y :disabled="!isOtaRolloutsAdmin">
                  <template #activator="{ props }">
                    <v-btn v-bind="props" icon="mdi-dots-vertical" :disabled="!isOtaRolloutsAdmin" />
                  </template>

                  <v-list>
                    <v-list-item
                      v-if="isOtaRolloutsAdmin && (item.state === 'pending' || item.state === 'disabled')"
                      class="text-warning"
                      title="Archive rollout"
                      prepend-icon="mdi-archive"
                      @click="showPreviewDialog({ title: 'Archive', value: 'archived' }, item)"
                    />

                    <v-list-item
                      v-if="isOtaRolloutsAdmin && item.state === 'pending'"
                      class="text-success"
                      title="Activate rollout"
                      prepend-icon="mdi-rocket-launch"
                      @click="showPreviewDialog({ title: 'Activate', value: 'active' }, item)"
                    />

                    <v-list-item
                      v-if="isOtaRolloutsAdmin && item.state === 'disabled'"
                      class="text-success"
                      title="Continue rollout"
                      prepend-icon="mdi-play"
                      @click="showPreviewDialog({ title: 'Continue', value: 'active' }, item)"
                    />

                    <v-list-item
                      v-if="isOtaRolloutsAdmin && item.state !== 'disabled'"
                      class="text-error"
                      title="Disable rollout"
                      prepend-icon="mdi-pause"
                      @click="showPreviewDialog({ title: 'Disable', value: 'disabled' }, item)"
                    />

                    <!-- <v-list-item title="View rollout stats" prepend-icon="mdi-chart-bar" />-->
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-data-table>

          <v-data-table
            v-if="tab === 'otafiles'"
            :loading="isLoading"
            :headers="otafileHeaders"
            :items="firmwareOtaFiles"
            :items-per-page="20"
            :items-per-page-options="[
              { title: '20', value: 20 },
              { title: '50', value: 50 },
              { title: '100', value: 100 },
            ]"
          >
            <template #item.uploader="{}">Uploader info not available</template>

            <template #item.created_at="{ item }">
              {{ new Date(item.created_at).toUTCString() }}
            </template>

            <template #item.state="{ item }">
              <span class="state" :class="item.state">{{ item.state.toUpperCase() }}</span>
            </template>

            <template #item.actions="{}">
              <div @click.stop="">
                <v-menu left offset-y>
                  <template #activator="{ props }">
                    <v-btn v-bind="props" icon="mdi-dots-vertical" :disabled="!isOtaRolloutsAdmin || true" />
                  </template>
                </v-menu>
              </div>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>

  <CreateRollout
    v-if="createDialog"
    v-model="routeParams.appFlavor"
    :open="createDialog"
    :hw="routeParams.hwType"
    @close="closeCreateDialog($event)"
  />

  <PreviewRollout
    :open="previewDialog"
    :action="action"
    :rollout="rollout"
    @confirm="changeRolloutState()"
    @close="closePreviewDialog()"
  />
</template>

<script lang="ts">
  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { RouteParams } from '@jouzen/outo-toolkit-vuetify'
  import { ALL_RING_TYPES } from '@jouzen/outo-toolkit-vuetify'

  import {
    appFlavors,
    appPlatforms,
    otafileHeaders,
    otafileStates,
    rolloutHeaders,
    rolloutStates,
  } from '#views/ota/constants'

  import { AppStore, OtaStore } from '#stores'

  @Component
  class RolloutsView extends mixins(RouteParams) {
    @Prop() public tab!: string

    public readonly env = import.meta.env.VITE_APP_ENV

    public routeParams: any = {
      hwType: 'gen2x',
      appFlavor: this.env === 'release' ? 'release' : this.env === 'staging' ? 'staging' : 'debug',
      appPlatform: '',
      rolloutState: '',
      otafileState: '',
    }

    public action: any = null
    public rollout: any = null

    public createDialog = false
    public previewDialog = false

    public readonly hwTypes = ALL_RING_TYPES

    public readonly appFlavors = appFlavors
    public readonly appPlatforms = appPlatforms
    public readonly rolloutStates = rolloutStates
    public readonly otafileStates = otafileStates
    public readonly rolloutHeaders = rolloutHeaders
    public readonly otafileHeaders = otafileHeaders

    private readonly appStore = new AppStore()
    private readonly otaStore = new OtaStore()

    public get isLoading() {
      return this.otaStore.loading
    }

    public get firmwareRollouts() {
      return (this.otaStore.rollouts?.[this.routeParams.hwType ?? 'gen2x'] ?? []).filter(
        (r: any) =>
          (!r.flavor || r.flavor === this.routeParams.appFlavor) &&
          (!this.routeParams.appPlatform || r.platform === this.routeParams.appPlatform) &&
          (!this.routeParams.rolloutState || r.state === this.routeParams.rolloutState),
      ) as any[]
    }

    public get firmwareOtaFiles() {
      return (this.otaStore.files?.[this.routeParams.hwType] ?? []).filter(
        (f: any) =>
          f.type === `firmware_${this.routeParams.hwType}` &&
          (!this.routeParams.otafileState || f.state === this.routeParams.otafileState),
      ) as any[]
    }

    public get isOtaRolloutsAdmin() {
      return this.appStore.isOtaRolloutsAdmin
    }

    public mounted() {
      this.refreshData()
    }

    public refreshData() {
      this.otaStore.listFiles()

      this.otaStore.listRollouts()
    }

    public closeCreateDialog(props: any) {
      this.createDialog = false

      if (props) {
        this.routeParams.hwType = props.hw
        this.routeParams.appFlavor = props.app
      }
    }

    public showPreviewDialog(action: any, rollout: any) {
      this.action = action
      this.rollout = rollout

      this.previewDialog = true
    }

    public closePreviewDialog() {
      this.action = null
      this.rollout = null

      this.previewDialog = false
    }

    public async changeRolloutState() {
      await this.otaStore.changeState({
        state: this.action.value,
        rollout: this.rollout,
      })

      this.closePreviewDialog()
    }
  }

  export default toNative(RolloutsView)
</script>

<style lang="scss" scoped>
  .state {
    &.active,
    &.verified {
      color: green;
    }

    &.pending {
      color: orange;
    }

    &.disabled {
      color: black;
    }

    &.invalid {
      color: red;
    }
  }
</style>
