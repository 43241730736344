<template>
  <div>
    <v-app-bar>
      <v-tabs style="max-width: 600px" :model-value="tab">
        <v-tab
          v-for="tab in starterTabs"
          :key="tab.value"
          :to="activeTask ? '/tasks/' + tab.value + '/' + task + '/' + activeTask : '/tasks/' + tab.value"
        >
          {{ tab.title }}
        </v-tab>
      </v-tabs>

      <v-spacer />

      <TasksSearch v-model:search="routeParams.searchText" />

      <v-menu location="bottom end" offset="16" min-width="200" :close-on-content-click="false">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            icon="mdi-filter-variant-plus"
            :color="props.value || !!routeParams.extraFilters.length ? 'primary' : ''"
          />
        </template>

        <v-list class="px-4 pb-6">
          <v-list-subheader>Analyzer tasks listing</v-list-subheader>

          <v-list-item v-for="(item, index) in filterItems" :key="index">
            <v-switch
              style="width: 330px"
              :label="item.title"
              :model-value="routeParams.extraFilters.includes(item.value)"
              @update:model-value="
                $event
                  ? (routeParams.extraFilters = [...routeParams.extraFilters, item.value])
                  : (routeParams.extraFilters = [...routeParams.extraFilters.filter((f) => f !== item.value)])
              "
            />
          </v-list-item>

          <v-divider class="my-4" />

          <v-list-item class="my-n2" title="Save as browser defaults..." @click="saveDefaultFilters()" />
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-container>
      <v-row>
        <v-col cols="9">
          <div class="text-h5 font-weight-light">Tasks are jobs that analyze large number of rings</div>

          <div class="text-subtitle-2 text-grey-darken-2 font-weight-light">
            <template v-if="tab === 'random'">With random samples analyzed rings are picked from random users</template>
            <template v-else-if="tab === 'select'">
              With select samples analyzed rings are picked from the given users
            </template>
            <template v-else-if="tab === 'upload'">
              With upload samples analyzed rings are picked from uploaded list of users
            </template>
          </div>
        </v-col>

        <v-col class="d-flex justify-end align-top" cols="3">
          <v-btn color="primary" :text="'Run ' + tab + ' analyzer'" @click="createDialog = true" />
        </v-col>
      </v-row>

      <v-row class="d-flex flex-grow-1 flex-shrink-1 mt-4" style="max-height: calc(100vh - 290px)">
        <template v-if="loading && !allTasks.length">
          <v-progress-circular indeterminate size="96" class="ma-auto" style="margin-top: 25vh !important" />
        </template>

        <template v-else-if="!loading && !activeTask && !tasks.length">
          <div class="ma-auto text-h6 font-weight-light" style="margin-top: 25vh !important">
            No tasks matching the search filters / selected jzlogs source

            <v-select
              v-model="routeParams.source"
              class="ma-auto mt-12"
              :items="jzlogSources"
              label="Jzlogs source"
              style="max-width: 300px"
              @update:model-value="updateSampleFilters()"
            />
          </div>
        </template>

        <template v-else>
          <v-col sm="6" md="4" cols="12" class="flex-shrink-1">
            <v-toolbar rounded="true" style="border-bottom: 2px solid purple; max-width: calc(100% - 24px)">
              <v-toolbar-title class="text-button text-capitalize">Jzlogs source</v-toolbar-title>

              <v-menu location="bottom end" offset="16" min-width="200">
                <template #activator="{ props }">
                  <v-chip v-bind="props" class="font-weight-bold mr-3" append-icon="mdi-chevron-down">
                    {{ jzlogSources.find((s: any) => s.value === routeParams.source)?.title || '' }}
                  </v-chip>
                </template>

                <v-list class="px-4 pb-6">
                  <v-list-item
                    v-for="(source, index) in jzlogSources"
                    :key="index"
                    :title="source.title"
                    @click="routeParams.source = source.value"
                  />
                </v-list>
              </v-menu>
            </v-toolbar>

            <div class="overflow-y-auto" style="position: relative; max-height: calc(100vh - 360px)">
              <v-list class="my-6 mx-n3 pa-0" style="background: transparent; width: 100%">
                <v-list-item v-for="(task, index) in tasks" :key="task.uid" class="mt-n3 mb-6 pa-0">
                  <div class="pa-3">
                    <TaskItem
                      :task="task"
                      :index="index"
                      :active="activeTask === task.uid"
                      :source="routeParams.source"
                    />
                  </div>
                </v-list-item>

                <v-list-item
                  v-if="show !== tasks.length"
                  title="Show more"
                  class="mx-3 mt-n3 text-center"
                  @click="showMore()"
                />
              </v-list>
            </div>
          </v-col>

          <v-col cols="12" sm="6" md="8" class="d-flex pl-4" style="max-height: calc(100vh - 265px)">
            <v-card class="d-flex flex-column" style="min-width: 100%">
              <v-toolbar>
                <v-tabs v-model="activeTab" grow>
                  <v-tab>Summary</v-tab>

                  <v-tab @click="showPreview = false">Samples</v-tab>

                  <v-tab @click="resultsTab = !results[0] || results[0].length > 1 ? 0 : 1">
                    Results

                    <v-menu offset-y bottom left>
                      <template #activator="{ props }">
                        <v-btn
                          v-bind="props"
                          rounded="0"
                          variant="text"
                          icon="mdi-menu-down"
                          class="align-self-center mr-n4"
                        />
                      </template>

                      <v-list class="grey-lighten-3">
                        <v-list-item title="Summary task" :disabled="resultsTab === 0" @click="resultsTab = 0" />
                        <v-list-item title="Analyze task" :disabled="resultsTab === 1" @click="resultsTab = 1" />
                      </v-list>
                    </v-menu>
                  </v-tab>

                  <v-tab
                    @click="
                      (warningsTab = !warnings[0] || warnings[0].length || !warnings[1].length ? 0 : 1),
                        listWarningFile()
                    "
                  >
                    Warnings
                    <v-menu offset-y bottom left>
                      <template #activator="{ props }">
                        <v-btn
                          v-bind="props"
                          rounded="0"
                          variant="text"
                          icon="mdi-menu-down"
                          class="align-self-center mr-n4"
                        />
                      </template>

                      <v-list class="grey-lighten-3">
                        <v-list-item title="Summary task" :disabled="warningsTab === 0" @click="warningsTab = 0" />
                        <v-list-item title="Analyze task" :disabled="warningsTab === 1" @click="warningsTab = 1" />
                      </v-list>
                    </v-menu>
                  </v-tab>

                  <v-tab>Task Details</v-tab>
                </v-tabs>

                <v-spacer />

                <HoverCopy icon :data="activeTask" :message="'Copy UID to clipboard'" />

                <v-tooltip v-if="previewUrl" location="top">
                  <template #activator="{ props }">
                    <v-btn v-bind="props" icon="mdi-open-in-new" :disabled="!previewUrl" @click="openPreview()" />
                  </template>

                  Open preview
                </v-tooltip>
              </v-toolbar>

              <v-progress-linear v-if="loading" height="2" indeterminate style="margin-top: -2px" />

              <v-alert v-if="warningStatus" class="flex-shrink-0" type="warning" icon="mdi-alert-outline">
                There were some issues encountered during the task, you can view them in the warnings tab
              </v-alert>

              <v-card-text class="flex-grow flex-shrink fill-height" style="min-height: 0">
                <v-window v-model="activeTab" class="flex-grow flex-shrink fill-height">
                  <v-window-item class="flex-grow flex-shrink fill-height" style="overflow: auto">
                    <iframe
                      v-if="!summary && previewUrl && state === 'SUCCEEDED'"
                      title="Preview"
                      class="fill-height fill-width px-6 pb-12"
                      style="border: none; min-width: 100%; margin-bottom: -6px"
                      :src="previewUrl"
                    />

                    <div v-else-if="activeTask && state !== 'SUCCEEDED'" class="pa-10 fill-height fill-width">
                      Task is in
                      <strong>{{ state }}</strong>
                      state therefore summary is not yet available.
                    </div>

                    <div
                      v-else-if="activeTask && !summary && !loading && summaryError"
                      class="pa-10 fill-height fill-width"
                    >
                      Unknown error occurred and therefore no summary available.
                    </div>

                    <Summary
                      v-else-if="summary && state === 'SUCCEEDED'"
                      :summary="summary"
                      :source="routeParams.source"
                      :task-parameters="taskParameters"
                    />

                    <div v-else-if="!summary" class="pa-10">Loading task summary...</div>
                  </v-window-item>

                  <v-window-item class="flex-grow flex-shrink fill-height" style="overflow: auto">
                    <Samples v-model="showPreview" :active="activeTask" :source="routeParams.source" />
                  </v-window-item>

                  <v-window-item class="flex-grow flex-shrink fill-height" style="overflow: auto">
                    <v-list v-if="results && !!results.length" class="pa-6">
                      <v-list-item
                        v-for="result in results[resultsTab]"
                        :key="result.key"
                        link
                        @click="downloadFile(result.key)"
                      >
                        <v-list-item-title>
                          {{ result.key.split('/').pop() }}
                        </v-list-item-title>

                        <template #append>
                          <p class="text-no-wrap mr-8">{{ getSize(result.size) }}</p>

                          <v-icon>mdi-cloud-download</v-icon>
                        </template>
                      </v-list-item>
                    </v-list>
                  </v-window-item>

                  <v-window-item class="flex-grow flex-shrink fill-height" style="overflow: auto">
                    <div v-if="!loading && !warningStatus" class="px-12 py-8">
                      No issues detected during the {{ warningsTab ? 'analyze' : 'summary' }} task run
                    </div>

                    <v-list v-else class="pa-6">
                      <v-list-group v-for="warning in warnings[warningsTab]" :key="warning.key">
                        <template #activator="{ props }">
                          <v-list-item v-bind="props" :title="'Warnings reported from ' + warning.type + ' step'" />
                        </template>

                        <v-list-item v-for="(data, index) in warning.data" :key="index" class="px-8">
                          <v-list-item-subtitle class="text--primary">
                            {{ warning.message }}

                            <span v-for="key in warning.details" :key="key" class="pr-1">
                              {{ key }} {{ data[key] }}
                            </span>
                          </v-list-item-subtitle>
                        </v-list-item>
                      </v-list-group>
                    </v-list>
                  </v-window-item>

                  <v-window-item style="overflow: auto">
                    <v-table density="comfortable">
                      <thead>
                        <tr>
                          <th scope="col" class="text-primary text-left">Parameter</th>
                          <th scope="col" class="text-primary text-left">Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(_, key) in taskParameters" :key="key">
                          <td>{{ getParameterKey(key) }}</td>
                          <td>{{ getParameterValue(key) }}</td>
                        </tr>
                      </tbody>
                    </v-table>
                  </v-window-item>
                </v-window>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-container>

    <CreateTask
      v-if="createDialog"
      v-model:source="routeParams.source"
      :open="createDialog"
      :mode="tab"
      @close="createDialog = false"
    />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { Debounce, RouteParams } from '@jouzen/outo-toolkit-vuetify'

  import { filterTasks, jzlogSources, taskStarterTabs } from '#views/tasks/constants'

  import { getParamValue } from '#views/tasks/utilities'

  import { PrefsStore, SetupStore, TasksStore } from '#stores'

  import { BatchJob, SummaryJson, TaskParameters } from '#types'

  @Component
  class TasksView extends mixins(RouteParams) {
    @Prop() public tab!: string
    @Prop() public uid!: string
    @Prop() public task!: string

    public readonly env = import.meta.env.VITE_APP_ENV

    public routeParams = {
      source: this.env == 'release' ? 'release' : this.env === 'staging' ? 'staging' : 'debug',
      searchText: '',
      extraFilters: [] as string[],
    }

    public activeTab = 0
    public resultsTab = 0
    public warningsTab = 0

    public previewUrl = ''

    public showPreview = false
    public summaryError = false
    public createDialog = false

    public summary: null | SummaryJson = null

    public readonly filterItems = filterTasks
    public readonly jzlogSources = jzlogSources

    public readonly starterTabs = taskStarterTabs

    private readonly tasksStore = new TasksStore()
    private readonly prefsStore = new PrefsStore()
    private readonly setupStore = new SetupStore()

    private interval: number | undefined = undefined

    public taskParameters: TaskParameters | undefined = undefined

    public get show() {
      return this.tasksStore.show
    }

    public get tasks() {
      return this.tasksStore.list
    }

    public get state() {
      return this.tasksStore.state
    }

    public get results() {
      return this.tasksStore.results
    }

    public get loading() {
      return this.tasksStore.loading
    }

    public get fetching() {
      return this.tasksStore.fetching
    }

    public get warnings() {
      return this.tasksStore.warnings
    }

    public get allTasks() {
      return this.tasksStore.fullList
    }

    public get activeTask() {
      return this.tasksStore.activeTask
    }

    public get warningStatus() {
      return this.tasksStore.warningStatus
    }

    public get taskFilterPrefs() {
      return this.prefsStore.taskFilters
    }

    public get activeTaskDetails() {
      return this.tasksStore.activeTaskDetails
    }

    public get sampleFilters() {
      return this.setupStore.sampleFilters
    }

    @Watch('tab', { immediate: true })
    protected tabChanged() {
      this.tasksStore.setActiveTab(this.tab)

      if (this.routeParams.extraFilters.some((name: string) => name === 'sample')) {
        this.updateTaskList()
      }
    }

    @Watch('state')
    protected stateChanged() {
      this.previewUrl = ''

      this.summary = null

      this.updateView()
    }

    @Watch('activeTask', { immediate: true })
    protected activeTaskChanged() {
      if (this.activeTask) {
        this.activeTab = 0
        this.resultsTab = 0
        this.warningsTab = 0

        this.showPreview = false

        const task = this.allTasks.find((task) => task.uid === this.activeTask)

        const taskName = task?.taskName || this.task || 'poirot-analyze-firmware'

        this.tasksStore.getTaskDetails(this.routeParams.source, { taskName: taskName, uid: this.activeTask })

        this.$router
          .replace({ path: `/tasks/${this.tab}/${taskName}/${this.activeTask}`, query: this.$route.query })
          .catch(() => {})

        this.updateView()
      } else {
        this.$router.replace({ path: `/tasks/${this.tab}`, query: this.$route.query }).catch(() => {})
      }
    }

    @Watch('routeParams', { immediate: true })
    protected routeParamsChanged() {
      this.updateTaskList()
    }

    @Watch('taskFilterPrefs', { immediate: true })
    protected taskFilterPrefsChanged() {
      this.routeParams.extraFilters = this.taskFilterPrefs
    }

    @Watch('activeTaskDetails', { immediate: true })
    protected activeTaskDetailsChanged() {
      this.updateView()
    }

    @Watch('routeParams.source', { immediate: false })
    protected routeParamsJzlogSourceChanged() {
      this.pollTasks(true)
    }

    @Debounce(500)
    protected updateTaskList() {
      this.tasksStore.filterTasks({
        source: this.routeParams.source,
        search: this.routeParams.searchText,
        filters: this.routeParams.extraFilters,
      })
    }

    public async mounted() {
      this.pollTasks(true)

      if (this.uid) {
        this.tasksStore.setActiveTask(this.uid)
      }

      this.interval = setInterval(this.pollTasks, 60000)

      this.setupStore.listSampleFilters(this.routeParams.source)
    }

    public beforeUnmount() {
      this.tasksStore.setActiveTask(null)

      clearInterval(this.interval)
    }

    public getSize(size: number) {
      return size < 1000 ? `${size} B` : `${(size / 1048576).toFixed(2)} MB`
    }

    public getParameterKey(key: string) {
      return key
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    }

    public getParameterValue(name: keyof TaskParameters) {
      if (this.taskParameters) {
        return getParamValue(name, this.taskParameters)
      }
    }

    public showMore() {
      this.tasksStore.showMore()
    }

    public openPreview() {
      window.open(this.previewUrl, '_blank')
    }

    public listWarningFile() {
      this.tasksStore.getWarningsJson(this.routeParams.source, this.results)
    }

    public downloadFile(file: string) {
      this.tasksStore.getDownloadUrl(this.routeParams.source, { bucket: 'results', prefix: file }).then((data) => {
        window.open(data.fileUrl)
      })
    }

    public saveDefaultFilters() {
      this.prefsStore.updatePrefs({ key: 'taskFilters', value: this.routeParams.extraFilters })
    }

    public updateSampleFilters() {
      this.setupStore.listSampleFilters(this.routeParams.source)
    }

    private pollTasks(initial: boolean) {
      this.tasksStore.listTasks(this.routeParams.source, initial)
    }

    /**
     * Update active task details view.
     *
     * Calling this function triggers task details UI update. "Loading task summary..." text will be shown and
     * then actual is rendered after a short while. This function should be called only when active task or
     * it's data has changed.
     *
     * @private
     */
    @Debounce(200)
    private async updateView() {
      this.summaryError = false

      if (this.activeTask && this.activeTask === this.activeTaskDetails?.uid) {
        this.taskParameters = this.activeTaskDetails?.parameters

        this.summary = null

        this.previewUrl = ''

        await this.tasksStore.listSamples({
          jobId: this.activeTask,
          taskDetails: this.activeTaskDetails,
        })

        await this.tasksStore.listResults(this.routeParams.source, {
          jobId: this.activeTask,
          taskDetails: this.activeTaskDetails,
        })

        const summaryJob = this.activeTaskDetails?.batchJobs?.find(
          (bj: BatchJob) => bj.jobType === 'poirot-summary-analysis',
        )

        if (this.state === 'SUCCEEDED' && summaryJob) {
          const file = await this.tasksStore.getDownloadUrl(this.routeParams.source, {
            bucket: 'results',
            prefix: `summary/${summaryJob?.uid}/summary.json`,
          })

          if (file?.fileUrl) {
            const resJson = await fetch(file.fileUrl)

            if (resJson.status === 200) {
              const json = await resJson.json()

              this.summary = json
            } else {
              this.summaryError = true
            }
          }

          const txt = await this.tasksStore.getDownloadUrl(this.routeParams.source, {
            bucket: 'results',
            prefix: `summary/${summaryJob?.uid}/summary.txt`,
          })

          if (txt?.fileUrl) {
            const resTxt = await fetch(txt.fileUrl)

            if (resTxt.status === 200) {
              this.previewUrl = txt.fileUrl
            }
          }
        }
      }
    }
  }

  export default toNative(TasksView)
</script>

<style lang="scss" scoped>
  :deep(.v-input--selection-controls .v-radio > .v-label),
  :deep(.sample .v-input--selection-controls .v-input__slot > .v-label) {
    font-size: 0.9rem;
  }
</style>
